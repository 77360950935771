import * as React from 'react';
import axios from 'axios';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { Layout } from '../components/layout';
import { Seo } from '../components/seo';
import * as Styles from '../styles/pages/_login.module.scss';
import * as CommonStyles from '../styles/global/_common.module.scss';
import { BreadCrumb } from '../components/breadcrumb';
import { BackGround } from '../components/background';
import {Link, useIntl} from "gatsby-plugin-react-intl"

const ArtistRegistrationPage = ({ location }) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [submitError, setSubmitError] = React.useState();
  const [submitComplete, setSubmitComplete] = React.useState();

  const onSubmit = async ({ data }) => {
    const apiKey = process.env.LIMITS_API_KEY
    const apiUrl = process.env.LIMITS_API_URL
    const endpoint = `${apiUrl}/login`
    await axios
      .post(endpoint, data, { headers: { 'Content-Type': 'application/json', 'X-MICROCMS-API-KEY': apiKey } })
      .then(() => {
        setSubmitComplete(true);
        reset();
        setTimeout(() => {
          setSubmitComplete(false);
        }, 3000);
      })
      .catch((error) => {
        if (error.response) {
          setSubmitError(true);
          setTimeout(() => {
            setSubmitError(false);
          }, 3000);
        }
      });
  };

  const colorStyle = {
    color: '#dc3545'
  };

  return (
    <Layout location={location}>
      <Seo title={t('contact.seo.title')} description={t('contact.seo.description')} />
      <BreadCrumb
        list={[
          {
            title: 'TOP',
            link: '/',
          },
        ]}
        currentPage={t('login.artistRegistration')}
      />
      <BackGround>
        <section className={CommonStyles.wrapper}>
          <div className={CommonStyles.container}>
            <div id="users" className={Styles.users}>
              <h1>LIMITS アーティスト登録申請</h1>
              <div className={Styles.mg_b2}>
                あなたもアーティスト申請をして、LIMITSに参加しよう！<br/>
                ※ 参加には運営事務局による審査があります。
              </div>

              <section>
                <h2>基本情報</h2>
                <div className={Styles.mg_b1}>
                  <label className={Styles.icon_required} htmlFor="artist_name">アーティスト名</label>
                  <input className={Styles.is_valid} type="text" name="artist[name]" id="artist_name" required />
                  {/*!-- ↑通常時はclassなし。登録に問題がない状態のときにclass.is-validを追加 --*/}
                  {/*!--エラー文言ここから// --*/}
                  <small><span className={Styles.color_error}>アーティスト名はすでに存在します。</span></small>
                  {/*!-- //エラー文言ここまで --*/}
                  <small>サイト上で表示される名前です。既に存在するものは使用できません。</small>
                </div>
                <div className={Styles.mg_b1}>
                  <label className={Styles.icon_required} htmlFor="artist_name_alnum">アーティスト名（英語/ローマ字表記）</label>
                  <input className={Styles.is_invalid} type="text" name="artist[name_alnum]" id="artist_name_alnum" />
                  {/*!-- ↑通常時はclassなし。登録に問題がない状態のときにclass.is-validを追加 --*/}
                  {/*!--エラー文言ここから// --*/}
                  <small><span className={Styles.color_error}>アーティスト名(英語/ローマ字表記)はすでに存在します。</span></small>
                  {/*!-- //エラー文言ここまで --*/}
                  <small>
                    英語orローマ字表記で名前を入力してください。半角英数字/記号が使用できます。<br/>
                    アーティスト名と同じ場合も再度入力をお願いします。<br/>
                    既に存在するものは使用できません。
                  </small>
                </div>
                <hr/>
                <div className={Styles.notice}>
                  <h3>
                    <img
                      src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMjciIHZpZXdCb3g9IjAgMCAxMSAyNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeT0iMC40MjEzODciIHdpZHRoPSIxMC4wNjA2IiBoZWlnaHQ9IjI2LjE1NzYiIHJ4PSI1LjAzMDMiIGZpbGw9IiMyM0JBOTYiLz4KPGNpcmNsZSBjeD0iNS4wMzAxOSIgY3k9IjUuNDUyMDYiIHI9IjIuMDEyMTIiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo="
                      className={Styles.accent} alt="accent" />
                    NOTICE
                  </h3>
                  アーティスト審査が通過した後は、ご自身で上記項目を変更することが出来ません。<br/>
                  変更を希望される場合、運営スタッフへお問い合わせください。
                </div>
              </section>

              <section>
                <h2>アイコン画像</h2>
                <div className={Styles.flex_container}>
                  <div className={Styles.flex_item + ' ' + Styles.item_30 + ' ' + Styles.for_sp + ' ' + Styles.no_mg}>
                    <div className={Styles.text_center + ' ' + Styles.for_sp}>
                      <img className={Styles.img_thumb}
                           src="https://limits.jp/assets/icon/user_default-4bc542d435fc66e3b4af93808c2d8ec65ff9d78819e6b55809bdc693ef21742a.jpg" />
                    </div>
                    <div className={Styles.text_center + ' ' + Styles.mg_t1}>
                      <input type="checkbox" value="1" name="artist[remove_icon]" id="artist_remove_icon" />
                        <label htmlFor="artist_remove_icon">画像を削除する</label>
                    </div>
                  </div>
                  <div className={Styles.flex_item + ' ' + Styles.item_70 + ' ' + Styles.for_sp}>
                    <input type="file" name="artist[icon]" id="artist_icon" />
                    <div>
                      様々なところで使用されるあなたのアイコン画像です。<br/>
                      アスペクト比1:1 の jpg jpeg gif png が使用出来ます。<br/>
                      1024pxを超える画像は縮小されます。
                    </div>
                  </div>
                </div>
              </section>

              <section>
                <h2>プロフィール</h2>
                <div className={Styles.mg_b1}>
                  <label className={Styles.icon_required}>活動内容・自己紹介</label>
                  <ul className={Styles.tab_list + ' ' + Styles.list_5 + ' ' + Styles.mg_t1 + ' ' + Styles.mg_b1}>
                    <li className={Styles.icon_required + ' ' + Styles._small + ' ' + Styles.active}>日本語(原文)</li>
                    <li>English</li>
                    <li>한국</li>
                    <li>中文(簡体)</li>
                    <li>中文(繁体)</li>
                  </ul>
                  <div className={Styles.tab_content}>
                    <textarea rows="8" name="artist[description_ja]" id="artist_description_ja" required />
                    <small>あなたの活動についてアピールしましょう</small>
                  </div>
                  <hr/>
                  <div className={Styles.mg_t1}>
                    <small>
                      各言語向けの内容をあらかじめ用意しておくことが出来ます。<br/>
                      各言語向けの内容が存在しない場合、ユーザは原文を元に機械翻訳された内容を表示することが出来ます。<br/>
                      <span className={Styles.color_warning}>機械翻訳の為、ニュアンスが異なって訳されることがあります。</span><br/>
                      翻訳結果が表示されるまで、少し時間がかかることがあります。
                    </small>
                    <a href="#" className={Styles.btn_common_s + ' ' + Styles.mg_t1 + ' ' + Styles.mg_b1}>翻訳を見る</a>
                  </div>
                </div>
              </section>

              <section>
                <h2>SNSアカウント＆Website</h2>
                <div className={Styles.notice}>
                  <h3>
                    <img
                      src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMjciIHZpZXdCb3g9IjAgMCAxMSAyNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeT0iMC40MjEzODciIHdpZHRoPSIxMC4wNjA2IiBoZWlnaHQ9IjI2LjE1NzYiIHJ4PSI1LjAzMDMiIGZpbGw9IiMyM0JBOTYiLz4KPGNpcmNsZSBjeD0iNS4wMzAxOSIgY3k9IjUuNDUyMDYiIHI9IjIuMDEyMTIiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo="
                      className={Styles.accent} alt="accent"
                    />
                    NOTICE
                  </h3>
                  以下の項目を入力すると、アーティスト紹介ページにリンクが表示されます。<br/>
                  イベント参加申し込み時など、アーティスト審査の参考にさせていただきます。<br/>
                  不都合ない範囲で、なるべくご入力ください。
                </div>
                <div className={Styles.mg_b1}>
                  <label htmlFor="artist_sns_pixiv">pixivアカウント</label>
                  <input placeholder="01234567" type="text" name="artist[sns_pixiv]" id="artist_sns_pixiv" />
                    <small>
                      pixivアカウントをお持ちであれば入力してください。<br/>
                      マイページ等で確認出来るメンバーIDの”数字のみ”を入力してください。<br/>
                      例) https://www.pixiv.net/member.php?id=01234567 ← こちらです
                    </small>
                </div>
                <div className={Styles.mg_b1}>
                  <label htmlFor="artist_sns_twitter">Twitterアカウント</label>
                  <input placeholder="limits_daba" type="text" name="artist[sns_twitter]" id="artist_sns_twitter" />
                    <small>
                      Twitterアカウントをお持ちであれば入力してください。<br/>
                      @を含めずにユーザ名を入力してください。
                    </small>
                </div>
                <div className={Styles.mg_b1}>
                  <label htmlFor="artist_sns_instagram">Instagramアカウント</label>
                  <input placeholder="limits_daba" type="text" name="artist[sns_instagram]" id="artist_sns_instagram" />
                    <small>
                      Instagramアカウントをお持ちであれば入力してください。<br/>
                      例) https://www.instagram.com/limits_daba/ ← こちらです
                    </small>
                </div>
                <div className={Styles.mg_b1}>
                  <label htmlFor="artist_website_url">Website URL</label>
                  <input placeholder="http://limits.jp/" type="url" name="artist[website_url]" id="artist_website_url" />
                    <small>Websiteをお持ちであればURLを入力してください。</small>
                </div>
              </section>

              <div className={Styles.text_center}>
                <input type="submit" name="commit" value="登録する" data-disable-with="登録する" className={Styles.btn_primary} />
              </div>
            </div>
          </div>
        </section>
      </BackGround>
    </Layout>
  );
};

export default ArtistRegistrationPage;
